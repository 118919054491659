.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.nav-link{
  color: #fff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.submit-form {
  max-width: 700px;
  margin: auto;
}
wrapper {
  padding-top: 30px;
}
  
body h3 {
  margin-bottom: 25px;
}
  
.navbar-brand a {
  color: #ffffff;
}
  
.form-wrapper,
.table-wrapper {
  max-width: 500px;
  margin: 0 auto;
}
  
.table-wrapper {
  max-width: 700px;
}
  
.edit-link {
  padding: 7px 10px;
  font-size: 0.875rem;
  line-height: normal;
  border-radius: 0.2rem;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  margin-right: 10px;
  position: relative;
  top: 1px;
}
  
.edit-link:hover {
  text-decoration: none;
  color: #ffffff;
}
  
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
  
