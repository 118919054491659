.pagination-container {
    list-style-type: none; /* Removes the bullet points */
    padding-left: 0; /* Removes any default padding */
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.pagination-subcontainer {
    list-style-type: none;
    display: flex;
    gap: 10px;
}

.pagination-page {
    padding: 10px 15px;
    border: 1px solid #ddd;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination-page:hover {
    background-color: #f5f5f5;
}

.pagination-active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

.pagination-prev,
.pagination-next {
    padding: 10px 15px;
    border: 1px solid #ddd;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination-prev:hover,
.pagination-next:hover {
    background-color: #f5f5f5;
}
